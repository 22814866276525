import React from 'react';
import Landing from '../components/Landing';
const md = require('markdown-it')({ html: true });
const dedent = require('dedent-js');

const content = md.render(dedent`# Titre RNCP de Développeur Web - RNCP37805

**Dernière mise à jour : le 05/06/2024**

La formation Développeur Web, dispensée par Le Reacteur permet de passer titre RNCP de Développeur Web de niveau 5 (Bac+2). Sur cette page sont mentionnées les informations importantes concernant ce titre RNCP.

<p class="alert alert-info">🏆 Les statistiques de réussite à l'examen seront communiquées en fin d’année 2024.</p>

## Référentiel

Le titre RNCP de Développeur Web, délivré par WEBECOM, est référencé au répertoire national des certifications professionnelles, sous le numéro RNCP37805, en date du 19/07/2023. Le référentiel complet peut être consulté à l'adresse suivante : <a target="_blank" href="https://www.francecompetences.fr/recherche/rncp/37805/" rel="noopener noreferrer">francecompetences.fr/recherche/rncp/37805</a>.

## Objectifs de la formation

**1. Développer la partie front-end d'une application Web ou Web Mobile sécurisée :**
- Installer et configurer son environnement de travail en fonction du projet Web ou Web Mobile
- Maquetter des interfaces utilisateur Web ou Web Mobile
- Réaliser des interfaces utilisateur statiques Web ou Web Mobile
- Développer la partie dynamique des interfaces utilisateur Web ou Web Mobile

**2. Développer la partie back-end d'une application Web ou Web Mobile sécurisée :**
- Mettre en place une base de données relationnelle
- Développer des composants d'accès aux données SQL et NoSQL
- Développer des composants métier côté serveur
- Documenter le déploiement d'une application dynamique Web ou Web Mobile

## Compétences attestées

L'obtention du titre RNCP permet d'attester 3 blocs de compétences :

**1. Développement Front End de sites et applications Web :**
  - Utiliser les langages de balisage html et css (avec et sans framework) pour intégrer les maquettes graphiques en utilisant un éditeur de code
  - Produire l’encodage en tenant compte des différentes contraintes imposées par les différentes résolutions d’écrans et les navigateurs afin d’obtenir un affichage optimisé pour smartphones, tablettes et desktop
  - Considérer la diversité des publics, notamment en situation de handicap, afin d’améliorer l’expérience utilisateur, en mobilisant les différentes normes d’accessibilité. (Ex: RGAA)
  - Travailler sur une logique d’intégration réutilisable, organisée et synthétique, à des fins de lisibilité et de performances, en utilisant un système de “classes” génériques et fonctionnelles
  - Travailler le référencement naturel d’un site Internet tout au long de sa construction pour optimiser son positionnement en appliquant les recommandations pour les différents moteurs de recherche (hiérarchisation des contenus, sémantique, poids des pages…)
  - Enrichir l’interface de l’application ou du site Internet en utilisant le langage JavaScript afin d’apporter à la partie front end de l'interactivité et des animations
  - Valider les saisies utilisateurs dans les formulaires afin de vérifier l'échange de données entre l’utilisateur et le serveur à l'aide de méthodes de validation adaptées. (ex : expressions régulières)
  - Développer des fonctionnalités mettant en jeu des requêtes asynchrones avec le serveur afin d’apporter de la fluidité et de l'interactivité sans rechargement de page en utilisant des API
  - Optimiser les temps de développement en utilisant des ressources externes (librairies JavaScript) pour résoudre des problématiques de développement complexes

**2. Développement back end d’applications Web :**
- Synthétiser les données utiles à l'application en analysant le cahier des charges afin de formaliser le modèle de données
- Construire la base de données à l'aide d’un outil d’administration de base de données, afin de permettre la bonne circulation des données nécessaires au fonctionnement de l’application
- Interroger la base de données par l'intermédiaire d’un langage de requêtes (SQL) pour permettre la manipulation et l’exploitation des données par l’application
- Respecter le cadre légal en utilisant les normes imposées par le règlement général sur la protection des données (RGPD) afin de garantir l'intégrité des utilisateurs et la protection des données
- Conceptualiser l’application, formaliser son schéma fonctionnel, à partir du cahier des charges fourni et des échanges avec le client, afin d’optimiser la charge serveur et les temps de réponse
- Développer une application en utilisant un langage de programmation adapté afin d’en construire l’architecture et les fonctionnalités côté serveur
- Développer une application  en utilisant la programmation orientée objet et les héritages afin de produire un code réutilisable et structuré
- Développer une application à l'aide d’une architecture Modèle-Vue-Contrôleur (MVC) afin d’assurer un code facile à maintenir et évolutif
- Identifier un utilisateur et délimiter ses champs d’action dans le but de sécuriser l’application par l’attribution de rôles spécifiques
- Travailler en équipe en utilisant des outils de collaboration et de gestion des versions afin de construire une application efficacement au sein d’une équipe de développeurs en entreprise
- Préparer l’application pour la livraison en s’assurant de sa conformité à la demande du client et son bon fonctionnement

**3. Développement avancé : comprendre et utiliser une architecture de type framework :**
- Confronter ses connaissances en développement avec la documentation d’un framework  dans le but de s’approprier l’architecture et les fonctionnalités d’un nouveau cadre de travail
- Configurer le framework en utilisant un gestionnaire de dépendances afin de préparer l’environnement de développement de l’application
- Développer une application évolutive avec un framework afin de disposer d’un environnement de développement standardisé pour l’ensemble du projet


##  Secteurs d'activité et type d'emploi

Une fois le titre RNCP obtenu, le candidat sera à même de travailler dans certains secteurs d'activité en lien avec ses compétences. Ceux-ci regroupent : 

- Les agences Web spécialisées dans la communication et le déploiement de sites Internet vitrines et E-commerce.
- Les entreprises de Services du Numérique (ESN) :  sociétés de services spécialisées en génie informatique, qui ont des missions de conception de solutions spécifiques et d’applications parmi d’autres activités.
- Plus généralement toute entreprise présentant un besoin de numérisation des processus ,souhaitant gagner en productivité, automatiser leurs différentes tâches et faciliter/accélérer leur déploiement sur plusieurs territoires. Ces évolutions passent par le développement d’outils d’automatisation très spécifiques non limités par à la mise en place de sites Internet et d'applications mobiles mais surtout par la construction spécifique d’outils et de fonctionnalités inhérentes à leur métier.  
- Les travailleurs indépendants (freelance)

**Les emplois accessibles :**

- Développeur Front End,
- Développeur Back End,
- Développeur Full Stack (qui regroupe les compétences du Front et du Back End)

## Quelles sont les modalités du passage du titre RNCP ? 

Le titre est obtenu en validant les 3 trois blocs de compétences ainsi qu’une période de stage en entreprise de 245h minimum (2 mois).

Pour valider le titre développeur Web, il faut obtenir une note supérieure ou égale à 50%. Le calcul de la moyenne des notes est pondéré de la manière suivante :
1. Epreuve : Projet de développement d’un site Web et d’une application Web 50%
2. Contrôle Continu 30%
3. Note de stage 20%

### Déroulé de l'épreuve : 
L’épreuve se déroule sur une durée totale de 5 jours : 
- 4 jours de travail individuel après réception du sujet
- 1 jour d’évaluation par le jury avec un oral de 30 minutes

## Prérequis au passage de l'examen 

Le titre RNCP37805 est composé de 3 blocs de compétences. Ces derniers peuvent être passés/validés simultanément ou de manière indépendante, par les apprenants ayant suivi la formation Développeur Web dispensée par Le Reacteur. Aussi, la réalisation d’un stage de minimum 2 mois est obligatoire.

<p class="alert alert-info">⚠️ Il n'y a aucune équivalence et/ou passerelle (certifications professionnelles, certifications ou habilitations) permettant d'obtenir le titre RNCP de Développeur Web.</p>

## Valider une VAE

La VAE permet d'obtenir, en totalité ou en partie le titre RNCP via [Webecom](https://www.webecom-formation.com/formation-acces-vae.html). 12 mois d'expérience professionnelle sont nécessaires pour faire une demande de VAE. Pour comprendre ce qu'est une VAE nous vous invitons à vous rendre à l'adresse suivante : <a target="_blank" href="https://www.education.gouv.fr/la-validation-des-acquis-de-l-experience-vae-3077">education.gouv.fr/la-validation-des-acquis-de-l-experience-vae-3077</a>.

## Le coût du passage de l'examen du titre RNCP 

Les apprenants qui suivent une formation incluant un passage du titre RNCP n'ont pas à régler quoi que ce soit pour un premier passage de l'examen.

Pour les apprenants qui suivent la formation en [e-learning et mentoring](https://www.lereacteur.io/cours-informatique/formation-developpeur-web/) ou dans le cas d'une VAE des frais de passage d’examen sont à prévoir.

## Quelle est la procédure pour passer le titre RNCP 

Tout d'abord, il conviendra de [nous contacter par mail](https://www.lereacteur.io/contact/) pour nous informer de votre souhait de passer l'examen.

À la suite de ça, nous vous proposerons, une date de passage de l'examen.Vous recevrez une convocation pour passer l'examen. Vous serez informé par mail du résultat de l'examen et, en cas de réussite, nous vous remettrons votre titre RNCP.

## Aménagements pour les personnes en situation de handicap

Toute personne en situation de handicap se verra proposer des solutions visant à lui faciliter le passage de l'examen du titre RNCP. Si le candidat a des difficultés pour se déplacer sur le lieu de l'examen, il lui sera proposé une évaluation à distance, grâce à la visio-conférence (Zoom ou équivalent). Notre équipe rentre en relation avec tous les candidats au titre RNCP, avant de valider une date de passage. C'est à ce moment là que les apprenants sont informés des aménagements éventuels, en fonction de la situation du candidat. Notre équipe attache une importance particulière à l'égalité des droits et des chances ; notre souhait est de faciliter le passage de l'examen.

Si vous pensez que cela vous concerne, vous devrez justifier de votre situation de handicap en nous fournissant tout document administratif délivré par une autorité compétente (RQTH, AAH…).

Notre contact référent handicap : **contact**[arobase]**lereacteur**[point]**io**.


## Informations complémentaires

**Liens avec d'autres certifications professionelles, certifications ou habilitation**

Le bloc de compétences RNCP37805BC01 (Développement Front End de sites et applications Web) correspond partiellement au bloc de compétences RNCP37805BC02 (Développer des interfaces frontend pour un site ou une application Web / Web Mobile) du titre RNCP35687 - Développeur Intégrateur Web.

`);

const Page = React.memo(({ location }) => {
  return (
    <Landing
      location={location}
      content={[{ type: 'md', content }]}
      pageName="Titre RNCP de Développeur Web - RNCP37805"
      metaTitle="Titre RNCP de Développeur Web | Le Reacteur"
    />
  );
});

export default Page;
